<template>
    <div>
        <div class="max-width">
            <el-breadcrumb separator-class="el-icon-arrow-right" style="height: 55px;" class="flex-r-center">
                <el-breadcrumb-item :to="{ path: '/allCate' }">医来顺</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/brand' }">品牌</el-breadcrumb-item>
                <el-breadcrumb-item>供应商</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="supplier">
                <div class="flex-r-center" style="padding-top: 20px;" v-if="info.logo">
                    <img :src="info.logo?info.logo:logo" alt="" style="width: 100px;margin-right: 20px;">
                    <span>{{info.name}}</span>
                </div>
                <div>
                    <h1>
                        <span>供应商简介</span></h1>
                    <div v-html="info.desc" v-if="info.desc"></div>
                    <el-empty description="暂时没有内容哦~" v-else></el-empty>

                </div>
                <div class="white-back">
                    <h1><span>产品展示</span></h1>
                    <div v-if="list.length">
                        <div class="all" >
                            <Product v-for="(sitem,index) in list" :key="index" :item="sitem"></Product>

                        </div>
                        <Pagination :total="goodTotal"  @changePage="changePage2" style="margin-top: 15px;"></Pagination>
                    </div>

                    <el-empty :image="emptyImg" :image-size="468" description="暂时没有产品哦" class="empty" v-else/>
                </div>
                <div>
                    <h1><span>新闻动态</span></h1>
                    <div v-if="artlist.length">
                        <Article v-for="(item,index) in artlist" :key="index" :info="item"></Article>
                        <Pagination :total="total" :size="3" @changePage="changePage" style="margin-top: 15px;"></Pagination>
                    </div>

                    <el-empty :image="emptyImg" :image-size="468" description="暂时没有新闻哦" class="empty" v-else/>
                </div>
                <div class="suppler-info">
                    <h1><span>供应商信息</span></h1>
                    <div class="fafafa">
                        <!--                   <div v-html="info.intro" v-if="info.intro"> </div>-->
                        <div class="company">
                            <div>
                                <p>公司名称：<span>{{info.company_name}}</span></p>
                                <p>成立时间：<span>{{info.build_date}}</span></p>
                            </div>
                            <div>
                                <p class="flex"><span  style="width: 130px;">经营范围：</span><span>{{info.business}}</span></p>
                                <p>注册地址：<span>{{info.company_addr}}</span></p>
                            </div>


                        </div>
                        <div class="other-info">
                            <div v-for="(item,index) in info.attr" class="first">
                            <p>{{item.name}}：<span>{{item.value}}</span></p>
                        </div></div>

                        <!--                    <el-empty description="暂时没有内容哦~" v-else></el-empty>-->
                    </div>
                    <div class="lience">
                        <h2>企业资质</h2>
                        <div class="all-photo" v-if="info.qual">
                            <el-image class="white-back"
                                      style="height: 300px;" fit="contain" :key="item"
                                      :src="item" alt="" v-for="item in info.qual.split(',')"
                                      :preview-src-list="info.qual.split(',')">
                            </el-image>
                        </div>
                        <el-empty description="暂时没有内容哦~" v-else></el-empty>
                    </div>

                </div>

            </div>

        </div>
    </div>

</template>

<script>
   import Pagination from "@/components/Pagination";
    import emptyImg from '@/assets/img/no_index.png';
    import logo from '@/assets/img/logo.png';
    import {gBrand, get_sup_by_brand} from "@/request/api";
    import Product from "@/components/Product";
    import Article from '@/components/Article';

    export default {
        name: "home",
        components: {Product, Article,Pagination},
        data() {
            return {
                emptyImg, logo,
                id: this.$route.query.id,
                nav: [
                    {title: '首页'},
                    {title: '公司介绍'},
                    {title: '产品中心'},
                ],
                list: [],
                artlist: [],
                form: {},
                info: {
                    logo: ''
                },
                googPage:1,goodTotal:0,
                page:1,
                total:0,
            }
        },
        created() {
            this.getGood();
            this.getList();
        },
        methods: {
            async getGood() {
                let res = await gBrand({brind: this.id, page:this.goodPage});
                this.list = res.data.list;
                this.goodTotal=res.data.count;

            },
            changePage(val){
               this.page=val;
               this.getList()
            },
            changePage2(val){
                this.goodPage=val;
                this.getGood()
            },
            async getList() {
                let res = await get_sup_by_brand({
                    brand_id: this.id,
                    page:this.page
                });
                this.total=res.data.total_count;
                this.artlist = res.data.news_list;
                this.info = res.data.sup;
                if(res.data.sup.attr&&res.data.sup.attr!='')res.data.sup.attr=JSON.parse(res.data.sup.attr)
                
            },
        }
    }
</script>

<style scoped lang="scss">
    .suppler-info .other-info{display: flex;
        .first span {line-height: 24px;}
    .first:nth-child(odd){
        width: 40%;
        border-right: 1px dashed #eaeaea;
        margin-bottom: 10px;
        flex-shrink: 0;
    }
        .first:nth-child(even){
            padding-left: 50px;
           margin-bottom: 10px;
        }
    }
    .supplier > div {
        overflow: auto;
        box-sizing: border-box;
        padding: 0 15px 15px;
        background: white;

        /*margin-bottom: 15px;*/
    }

    .supplier > div h1 span {
        font-size: 16px;
        line-height: 40px;
        height: 40px;
    }

    .supplier /deep/ .article h1 {
        font-size: 16px;
        font-weight: normal;
    }

    .supplier /deep/ .article .info {
        font-size: 14px;
        font-weight: normal;
    }

    .supplier /deep/ .product {
        border: 1px solid #eee;
        padding: 10px;

        &:hover {
            box-shadow: 0 0 3px $m-c;
        }
    }

    .supplier .all {
        overflow: initial !important;
        display: grid;
        grid-template-columns: repeat(5, 19%);
        grid-gap: 15px 1.25%;
    }

    /*.suppler-info{padding-top: 20px;}*/
    .supplier > div h1 span {
        background: $m-c;
        display: inline-block;
        color: white;
        padding: 0 10px;

    }

    .supplier > div {
        margin-bottom: 15px;
        background: white;
    }

    .supplier > div h1 {
        margin: 15px 0;
        display: flex;
        border-bottom: 1px solid $m-c;
    }

    .fafafa {
        background: #fafafa;
    }



    .supplier .suppler-info > div {

        background: #fafafa;
        padding: 15px;
    }

    .suppler-info .company > div:first-child {
        border-right: 1px dashed #eaeaea;
        width: 40%;
        flex-shrink: 0;
    }

    .suppler-info .company > div:last-child {
        padding-left: 50px;
    }

    .suppler-info .company {
        display: flex;
        /*flex-wrap: wrap;*/
        p{
            margin-bottom: 10px;
        }
    }

    .suppler-info .company p span{
        line-height: 24px;
    }

    .lience {
        border-top: 20px solid white;
    }

    .lience h2 {
        border-bottom: 1px solid #efefef;
        padding-bottom: 10px;
        margin-bottom: 20px;
        font-size: 16px;
    }

    .lience .all-photo {
        display: grid;
        grid-template-columns: repeat(5, 19%);
        grid-gap: 20px 1.25%;
        margin-bottom: 20px;

        img {
            width: 100%;
            height: 300px;
            object-fit: contain;
            background: white;
        }
    }
</style>
