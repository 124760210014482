<template>
    <div class="product pointer" @click="detail">
        <div>
            <div class="img center">

                <el-image :src="domain+item.main_image" class="error-img center" style="width:202px;height:202px;" lazy>
                    <div slot="error" class="image-slot">
                        <div slot="error" class="image-slot">
                            <img src="@/assets/img/logo.png" alt="" class="logo-img"/>
                        </div>
                    </div>
                </el-image>
            </div>


            <p class="fs-14 line-2 name" >
                <span v-if="item.brand" class="fs-14">【{{item.brand.name}}】</span>
                {{item.name}}</p>
        </div>
        <div v-if="!item.sku.id">
            <p class="r-f  price" v-if="item.sku[0].group_price&&item.sku[0].group_price!=item.sku[0].market_price">

                成交价: ￥{{item.sku[0].group_price}}

            </p><p class="r-f  price" v-else>

                市场价: ￥{{item.sku[0].market_price}}

            </p>
            <p class="g-f fs-12 cost_price">挂网价：￥{{item.sku[0].cost_price}}</p>
        </div>
        <div v-else>

            <p class="r-f  price" v-if="item.sku.group_price&&item.sku.group_price!=item.sku.market_price">
                成交价: ￥{{item.sku.group_price}}

            </p>
            <p class="r-f  price" v-else>
                市场价: ￥{{item.sku.market_price}}

            </p>
            <p class="g-f fs-12 cost_price">挂网价：￥{{item.sku.cost_price}}</p>
        </div>
    </div>
</template>

<script>
    import {domain} from '@/request/utils';

    export default {
        name: "product",
        props:{
            item:{
                type:Object
            }
        },
        data(){
            return {
                domain
            }
        },
        computed:{
            price(){
                console.log(this.item)
            }
        },
        methods:{
            detail(){
                if(this.item.is_sale==2){
                    return this.$message.error('该产品已下架')
                }
                const url = this.$router.resolve({path: '/productDetail', query: {id:this.item.id}})
                window.open(url.href,'_blank')
                // this.$emit('nextPage')
            }
        }
    }
</script>

<style scoped lang="scss">
    .product {
        /*padding: 15px 15px 0;*/
        padding-top: 15px;
        background: white;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width:100%;
        .img {
            width:100%;
           /*height: 100%;*/
            margin-bottom: 10px;
        }
        /*.error-img /deep/ .el-image__inner{object-fit: cover;}*/
       .logo-img {

            width: 100%;
            height: 260px;
            object-fit: contain;
        }

        .name {margin:2px 0 4px;}
        .cost_price {margin-top:5px;}
        .error-img /deep/ img:hover{
            transform: scale(1.2);

            position: relative;
            transition: 1s;  box-shadow: 0 0 2px $m-c inset;
        }
    }
</style>
