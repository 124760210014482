<template>
    <el-pagination
            class="page"
            :class="align"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="size"
            prev-text="上一页"
            next-text="下一页"
            :hide-on-single-page="true"
            layout="prev, pager, next,slot"
            :total="total">
        <span>共{{Math.ceil(total / size)}}页</span>
        <span style="margin:0 10px;">到第
        <input type="number" class="page-num" v-model="page"/>页
        </span>
        <span class="determine pointer" @click="changePage" v-if="disabled==false">确定</span>
        <span class="determine g-f" v-else>确定</span>
    </el-pagination>
</template>

<script>
    export default {
        props: {
            align: {
                type: String,
                default: 'flex-end'
            },
            total: {
                type: Number
            },
            size: {
                type: Number,
                default: 10
            }
        },
        name: "Pagination",
        data() {
            return {
                currentPage: 1,
                page: 1,
                totalPage: 0,
                disabled: false
            }
        },
        watch: {
            page(val) {

                if (val >= Math.ceil(this.total / this.size)) {
                    this.disabled = true;
                } else {
                    this.disabled = false;
                }
            }
        },
        methods: {
            handleCurrentChange(val) {
                this.page = val;
                this.$emit('changePage', val)

            }, changePage() {
                this.currentPage = Number(this.page);
                this.$emit('changePage', this.page)
            },

        },
        created() {
            this.totalPage = Math.ceil(this.total / this.size)
        }
    }
</script>

<style scoped lang="scss">
    .page {
        /deep/ span:not([class*=suffix]) {
            font-weight: normal;
        }

        /deep/ ul li, /deep/ button, .determine {
            border: 1px solid #ccc;
            margin-right: 10px;
            box-sizing: border-box;
            text-align: center;
            font-weight: normal;
            background: transparent;
        }

        .determine {
            width: 50px;
        }

        /deep/ .active {
            background: $m-c;
            color: white;
        }

        .page-num {
            border: 1px solid #ccc;
            padding: 0 4px;
            font-size: 13px;
            width: 35.5px;
            height: 28px;
            line-height: 28px;
            box-sizing: border-box;
            text-align: center;
            margin: 0 5px;

        }
    }


</style>
