<template>

    <div class="article flex pointer" @click="articlePage(info.id)">
        <div><img :src="domain+info.image" alt=""></div>
        <div class="flex-column">
            <h1 class="fs-18 c-pointer">{{info.title}}</h1>
            <p class="fs-15 line-3 info" v-html="info.info"></p>
            <div class="g-f flex-r-center laiyuan">
                <span>{{$fomartDate(info.createtime,10)}}</span>
                <span class="fs-15">{{info.laiyuan}}</span>
                <span class="flex-cc">
                         <i class="el-icon-view fs-18"></i>{{info.liulan}}</span></div>
        </div>
    </div>
</template>

<script>

    import {domain} from '@/request/utils'

    export default {
        name: "Article",
        props: {
            info: {
                type: Object
            }
        },
        data() {
            return {
                domain
            }
        },
        methods: {
            articlePage(id) {
                const url = this.$router.resolve({path: '/articleDetail', query: {id}})
                window.open(url.href, '_blank')
            }
        }


    }
</script>

<style scoped lang="scss">
    .article {
        .info {
            line-height: 25px;
            text-indent: 25px;
        }

        margin: 0 20px;
        padding: 20px 0;
        border-bottom: 1px solid #eee;

        img {
            width: 250px;
            height: 160px;
            margin-right: 30px;
            object-fit: fill;
        }

        & > div {
            justify-content: space-between;
        }
    }


    .g-f {
        text-indent: 0em;
    }

    .laiyuan {
        position: relative;

        span {
            padding-right: 10px;

            .el-icon-view {
                margin-right: 10px;
            }
        }

        span:nth-child(n+2) {
            margin-left: 10px;
        }

        span:nth-last-child(n+2):after {
            content: ' ';
            background: #eee;
            position: absolute;
            top: 4px;
            width: 1px;
            height: 12px;
            display: inline-block;
            margin-left: 10px;
            border-right: 1px solid #eee;
        }
    }

</style>
